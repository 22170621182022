export default function vapLoadingFix() {
	try {
		const vapBlock = document.querySelector('#vap');
		if (vapBlock) {
			if (
				document.querySelector('html').classList.contains('mobile') ||
				document.querySelector('html').classList.contains('tablet') ||
				document.querySelector('html').classList.contains('touch')
			) {
				// If mobile or tablet.
				document.querySelectorAll('.block-wrapper').forEach((b) => {
					b.remove();
				});
				document.querySelectorAll('section').forEach((b) => {
					if (!b.classList.contains('vap')) b.remove();
				});
			} else {
				// If Desktop.
				vapBlock.remove();
			}
		}
	} catch (error) {
		console.error(error);
	}
}
