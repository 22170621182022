export default function cocktailWidget(options = {}) {
	try {
		const block = document.querySelector('.cocktail-widget');

		setTimeout(() => {
			if (block) {
				const count = block.querySelector('.cocktail-widget__count');
				const siteHeader = document.querySelector('.site-header');
				const copyToClipboard =
					block.querySelector('.copy-to-clipboard');
				const originalURL = copyToClipboard.dataset.url;

				let timeout;

				// Move widget to bottom of page so it position fixed can work with footer
				document.body.appendChild(block);

				copyToClipboard.addEventListener('click', () => {
					const doneText = block.querySelector(
						'.sharing__clipboard-text__copy--done'
					);
					const normalText = block.querySelector(
						'.sharing__clipboard-text__copy'
					);

					if (doneText) {
						clearTimeout(timeout);
						doneText.classList.remove('hidden');
						normalText.classList.add('hidden');

						timeout = setTimeout(() => {
							doneText.classList.add('hidden');
							normalText.classList.remove('hidden');
						}, 2000);
					}
				});

				let myCocktails =
					JSON.parse(window.localStorage.getItem('myCocktails')) ||
					[];

				if (myCocktails.length) {
					block.classList.remove('hide');
					count.innerHTML = `(${myCocktails.length})`;
				}

				const mobileWidgetActions = () => {
					const mobileCocktailFilter = document.querySelector(
						'.cocktail-filter__filter'
					);
					const newCocktailList =
						JSON.parse(
							window.localStorage.getItem('myCocktails')
						) || [];

					if (
						newCocktailList.length &&
						!block.classList.contains('open')
					) {
						block.classList.add('open');

						const position = siteHeader.getBoundingClientRect();
						block.style.top = `${position.height}px`;
					}

					if (mobileCocktailFilter)
						mobileCocktailFilter.classList.toggle(
							'has-widget',
							newCocktailList.length
						);
				};

				const onScroll = () => {
					if (window.innerWidth > 768) {
						const {parentElement} = block;
						let parentSibling = parentElement.nextElementSibling;
						const footer = document.querySelector('footer');
						const newCocktailList =
							JSON.parse(
								window.localStorage.getItem('myCocktails')
							) || [];
						if (!parentSibling) {
							parentSibling = document.querySelector('footer');
						}

						block.style.top = '';

						block.classList.toggle(
							'hide',
							window.innerHeight + window.scrollY >=
								document.body.offsetHeight -
									footer.offsetHeight ||
								!newCocktailList.length
						);
					} else {
						mobileWidgetActions();
					}
				};

				document.addEventListener('scroll', onScroll);
				window.addEventListener('resize', onScroll);
				onScroll();

				if (!myCocktails) {
					window.localStorage.setItem(
						'myCocktails',
						JSON.stringify([])
					);

					myCocktails = JSON.parse(
						window.localStorage.getItem('myCocktails')
					);
				}

				const allAddToCollectionButtons =
					document.querySelectorAll('.add-to-collection');
				const allRemoveFromCollectionButtons =
					document.querySelectorAll('.remove-from-collection');
				const allSharingLinks = block.querySelectorAll('.sharing a');

				// change the sharing urls to include the cocktail slugs
				allSharingLinks.forEach((link) => {
					const baseURL = link.getAttribute('data-base-url');
					const newURL = `${baseURL}${originalURL}?shared=1&favourites=${myCocktails.join(
						','
					)}`;
					link.href = newURL;
				});

				copyToClipboard.dataset.url = `${originalURL}?shared=1&favourites=${myCocktails.join(
					','
				)}`;

				allAddToCollectionButtons.forEach((button) => {
					button.addEventListener('click', () => {
						setTimeout(() => {
							const newCocktailList =
								JSON.parse(
									window.localStorage.getItem('myCocktails')
								) || [];
							copyToClipboard.dataset.url = `${originalURL}?shared=1&favourites=${newCocktailList.join(
								','
							)}`;

							// change the sharing urls to include the cocktail slugs
							allSharingLinks.forEach((link) => {
								const baseURL =
									link.getAttribute('data-base-url');
								const newURL = `${baseURL}${originalURL}?shared=1&favourites=${newCocktailList.join(
									','
								)}`;
								link.href = newURL;
							});

							block.classList.remove('hide');
							count.innerHTML = `(${newCocktailList.length})`;
						}, 10);
					});
				});

				allRemoveFromCollectionButtons.forEach((button) => {
					button.addEventListener('click', () => {
						setTimeout(() => {
							const newCocktailList =
								JSON.parse(
									window.localStorage.getItem('myCocktails')
								) || [];
							copyToClipboard.dataset.url = `${originalURL}?shared=1&favourites=${newCocktailList.join(
								','
							)}`;

							// change the sharing urls to include the cocktail slugs
							allSharingLinks.forEach((link) => {
								const baseURL =
									link.getAttribute('data-base-url');
								const newURL = `${baseURL}${originalURL}?shared=1&favourites=${newCocktailList.join(
									','
								)}`;
								link.href = newURL;
							});

							if (!newCocktailList.length) {
								block.classList.add('hide');
							}
							count.innerHTML = `(${newCocktailList.length})`;
						}, 10);
					});
				});
			}
		}, 800);
	} catch (error) {
		console.error(error);
	}
}
