import {fadeIn, fadeOut} from '@pernod-ricard-global-cms/jsanimations';
import {isTablet} from '@pernod-ricard-global-cms/jsutils';

export default function siteHeaderJs(options = {}) {
	try {
		const {block} = options;

		/** start of code to trigger header submenu * */
		const menuItems = block.querySelectorAll('.site-header__nav-menu');
		const hamburger = block.querySelector('.site-header__mobile-menu');
		const closeMenu = block.querySelector('.site-header__close-container');
		const parentLinks = block.querySelectorAll(
			'.site-header__nav-menu .site-header__link'
		);
		const overlay = document.querySelector('.site-header__overlay');
		const nav = document.querySelector('.site-header__nav');

		let timeout;

		const mouseover = ({target}) => {
			clearTimeout(timeout);

			menuItems.forEach((menuItem) => {
				menuItem.classList.remove('show');
			});

			target.classList.add('show');
		};

		const mouseout = ({target}) => {
			timeout = setTimeout(() => {
				target.classList.remove('show');
			}, 200);
		};

		const onClick = (event) => {
			if (event.target.classList.contains('site-header__link')) {
				event.preventDefault();

				event.target.parentNode.classList.toggle('show');

				if (
					(event.target.nextElementSibling &&
						event.target.nextElementSibling.classList.contains(
							'site-header__nav-sub-menu'
						) &&
						!event.target.parentNode.classList.contains('show')) ||
					!event.target.nextElementSibling
				) {
					window.location.href = event.target.href;
				}
			}
		};

		const setupListeners = () => {
			if (window.innerWidth >= 768 || isTablet(navigator.userAgent)) {
				menuItems.forEach((menuItem) => {
					if (isTablet(navigator.userAgent)) {
						menuItem.addEventListener('click', onClick);
					} else {
						menuItem.addEventListener('mouseenter', mouseover);

						menuItem.addEventListener('mouseleave', mouseout);
					}
				});
			}
		};

		const removeListeners = () => {
			menuItems.forEach((menuItem) => {
				if (isTablet(navigator.userAgent)) {
					menuItem.removeEventListener('click', onClick);
				} else {
					menuItem.removeEventListener('mouseenter', mouseover);

					menuItem.removeEventListener('mouseleave', mouseout);
				}
			});
		};

		setupListeners();

		window.addEventListener('resize', () => {
			removeListeners();
			setupListeners();

			if (window.innerWidth >= 768) {
				nav.classList.remove('show');
				nav.classList.remove('mobile');
				overlay.classList.remove('show');
				fadeOut(overlay, 500);
			} else {
				nav.classList.add('mobile');
			}
		});

		parentLinks.forEach((parentLink) => {
			parentLink.addEventListener('click', (event) => {
				if (
					isTablet(navigator.userAgent) &&
					event.target.nextElementSibling &&
					event.target.nextElementSibling.classList.contains(
						'site-header__nav-sub-menu'
					) &&
					event.target.parentNode.classList.contains('show')
				) {
					event.preventDefault();
				}
			});
		});

		hamburger.addEventListener('click', () => {
			nav.classList.add('show');
			overlay.classList.add('show');
			overlay.classList.add('transparent');
			fadeIn(overlay, 500);
			document.body.style.overflow = 'hidden';
		});
		if (!closeMenu) {
			return;
		}
		closeMenu.addEventListener('click', (event) => {
			event.preventDefault();
			nav.classList.remove('show');
			overlay.classList.remove('show');
			fadeOut(overlay, 500);
			document.body.style.overflow = '';
		});

		overlay.addEventListener('click', () => {
			nav.classList.remove('show');
			overlay.classList.remove('show');
			overlay.classList.remove('transparent');
			fadeOut(overlay, 500);
			document.body.style.overflow = '';

			if (isTablet(navigator.userAgent)) {
				menuItems.forEach((menuItem) => {
					menuItem.classList.remove('show');
				});
			}
		});

		if (window.innerWidth < 768) {
			nav.classList.add('mobile');
		}
		/** end of code to trigger header submenu * */
	} catch (error) {
		console.error(error);
	}
}
