import {isEmailValid} from '@pernod-ricard-global-cms/jsutils';

export default function slidingFormJS(block) {
	if (block.querySelector('form')) {
		const currentMarket = document.documentElement.lang;
		const countrySelector = block.querySelector('#countryCode3');

		let currentMarketAfterDash = getSubstringAfterDash(currentMarket);

		const form = block.querySelector('form');

		const inputs = block.querySelectorAll(
			'.cdb_form_field input, .cdb_form_field select, .cdb_form_gdpr_information input, .cdb_form_privacypolicy_information input'
		);
		const backButton = block.querySelector(
			'.newsletter__submit-button--back'
		);
		const submitButton = block.querySelector('.newsletter__submit-button');
		const nextButton = block.querySelector('.newsletter__submit-next');
		const indicator = block.querySelector(
			'.newsletter__progress-indicator'
		);
		const indicatorContainer = block.querySelector(
			'.newsletter__progress-container'
		);
		const indicatorDescription = block.querySelector(
			'newsletter__progress-description'
		);
		const indicatorSuccess = block.querySelector(
			'newsletter__progress-success'
		);

		const messageContainer = block.querySelector('.newsletter__message');
		const message = block.querySelector('.newsletter__message-form');
		const fieldMessageContainer = block.querySelector(
			'.newsletter__message-fields'
		);
		const emailMessageContainer = block.querySelector(
			'.newsletter__message-email'
		);
		const dateMessageContainer = block.querySelector(
			'.newsletter__message-date'
		);
		const birthdayMessageContainer = block.querySelector(
			'.newsletter__message-birthday'
		);
		const description = block.querySelector('.newsletter__description');

		let currentElement = 0;

		let isFormValid = true;

		let percentage = (currentElement + 1) / inputs.length;

		// Make all inputs required

		const clearErrors = () => {
			block.querySelectorAll('.invalid').forEach((error) => {
				error.classList.remove('invalid');
			});

			block
				.querySelectorAll('.newsletter__message--show')
				.forEach((error) => {
					error.classList.remove('newsletter__message--show');
				});
		};

		const unfocussAll = () => {
			inputs.forEach((input, index) => {
				if (input.disabled) input.removeAttribute('disabled');
				if (index > currentElement + 1) {
					input.setAttribute('disabled', 'disabled');
					let parentElement = input.closest('.cdb_form_field')
						? input.closest('.cdb_form_field')
						: input.closest(
								'.cdb_form_gdpr_information, .cdb_form_privacypolicy_information'
						  );
					parentElement.classList.remove('active');
				}
			});
		};

		const focusElement = (id) => {
			let element = inputs[id];
			currentElement = parseInt(id);
			if (element) {
				unfocussAll();
				let parentElement = element.closest('.cdb_form_field')
					? element.closest('.cdb_form_field')
					: element.closest(
							'.cdb_form_gdpr_information, .cdb_form_privacypolicy_information'
					  );
				parentElement.classList.add('active');

				const isSafari = document
					.querySelector('html')
					.classList.contains('safari');
				const isTerms = element.type === 'checkbox';
				const extraMargin = isSafari && isTerms ? 7 : 0;

				form.scroll({
					left: element.offsetLeft - extraMargin - form.offsetLeft,
					behavior: 'smooth'
				});

				percentage = (currentElement + 1) / inputs.length;
				indicator.style.width = `${
					percentage * indicatorContainer.offsetWidth
				}px`;

				if (currentElement == inputs.length - 1) {
					nextButton.style.display = 'none';
					submitButton.style.display = 'inline-block';
				}
				if (currentElement > 0 && currentElement < inputs.length - 1) {
					backButton.style.display = 'inline-block';
				} else {
					backButton.style.display = 'none';
				}
				inputs[currentElement].focus();
			}
		};

		const validateForm = () => {
			isFormValid = true;
			clearErrors();
			inputs.forEach((input, index) => {
				if (index <= currentElement) {
					if (!validate(input)) {
						isFormValid = false;
						focusElement(
							block.querySelector('.invalid input').dataset.index
						);
						return isFormValid;
					}
				}
			});
			return isFormValid;
		};

		const validate = (element) => {
			if (!element) return;
			let isValid = true;
			const isRequired = element.getAttribute('required');

			if (isRequired != null) {
				if (element.type === 'checkbox') {
					if (!element.checked) isValid = false;
				} else if (element.value.length == 0) {
					fieldMessageContainer.classList.add(
						'newsletter__message--show'
					);
					isValid = false;
				}
			}

			if (element.type === 'email' && !isEmailValid(element.value)) {
				emailMessageContainer.classList.add(
					'newsletter__message--show'
				);
				isValid = false;
			}

			if (element.getAttribute('name') == 'birthday') {
				if (element.value == '') {
					isValid = false;
				}
				let dob = new Date(element.value);
				let diff_ms = Date.now() - dob.getTime();
				let age_dt = new Date(diff_ms);
				let age = Math.abs(age_dt.getUTCFullYear() - 1970);
				if (age < 18) {
					birthdayMessageContainer.classList.add(
						'newsletter__message--show'
					);
					isValid = false;
				}
			}

			if (!isValid) {
				let parentElement = element.closest('.cdb_form_field')
					? element.closest('.cdb_form_field')
					: element.closest(
							'.cdb_form_gdpr_information, .cdb_form_privacypolicy_information'
					  );
				parentElement.classList.add('invalid');
			}

			return isValid;
		};

		const keyDownListener = (event) => {
			if (event.keyCode === 13 || event.code === 'Tab') {
				event.preventDefault();
				nextElement();
			}
		};

		const nextElement = () => {
			if (validateForm()) {
				currentElement++;
				focusElement(currentElement);
			}
		};

		const submitForm = () => {
			if (validateForm()) {
				block.querySelector('.cdb-submit').click();
			}
		};

		function getSubstringAfterDash(str) {
			let parts = str.split('-');
			return parts.length > 1 ? parts.slice(1).join('-') : '';
		}

		if (countrySelector) {
			for (let i = 0; i < countrySelector.options.length; i++) {
				if (
					countrySelector.options[i].value.toLowerCase() ===
					currentMarketAfterDash
				) {
					if (
						countrySelector.options[countrySelector.selectedIndex]
					) {
						countrySelector.options[
							countrySelector.selectedIndex
						].removeAttribute('selected');
					}
					countrySelector.options[i].setAttribute(
						'selected',
						'selected'
					);
					break;
				}
			}
		}

		form.addEventListener('submit', () => {
			description.style.display = 'none';
			submitButton.style.display = 'none';
			indicatorDescription.display = 'none';
			indicatorSuccess.display = 'block';
		});

		inputs.forEach((input, index) => {
			input.dataset.index = index;

			let parentElement = input.closest('.cdb_form_field')
				? input.closest('.cdb_form_field')
				: input.closest(
						'.cdb_form_gdpr_information, .cdb_form_privacypolicy_information'
				  );
			parentElement.addEventListener('click', (event) => {
				input.focus();
			});
			input.addEventListener('change', (event) => {
				if (event.target.checked) {
					nextElement();
				}
			});

			//input.addEventListener('focusout', validateForm);

			input.addEventListener('focus', (event) => {
				if (event.target.dataset.index == currentElement) {
					if (currentElement == 0) focusElement(currentElement);
					return;
				} else if (validate(inputs[currentElement])) {
					focusElement(event.target.dataset.index);
				} else {
					focusElement(currentElement);
				}
			});
		});

		nextButton.addEventListener('click', nextElement);
		submitButton.addEventListener('click', submitForm);

		// Change progress bar on windows resize.
		window.addEventListener('resize', () => {
			indicator.style.width = `${
				percentage * indicatorContainer.offsetWidth
			}px`;
		});

		backButton.addEventListener('click', () => {
			const index = currentElement === null ? 0 : currentElement - 1;
			if (inputs[index]) {
				focusElement(index);
			}
		});

		unfocussAll();

		window.addEventListener('keydown', keyDownListener);
	}
}
