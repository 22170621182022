import { copyToClipboard, checkDevice, ttfb } from '@pernod-ricard-global-cms/jsutils';
import lazyloader from '@pernod-ricard-global-cms/cbllazyloader';
import assets from 'Assets/js/modules/jsAssets';
import errorPage from 'Assets/js/error';
import siteHeader from 'Assets/js/components/site-header';
import siteModal from 'Assets/js/modules/modal';
import welcomeMat from 'Assets/js/modules/welcome-mat';
import smoothscroll from 'smoothscroll-polyfill';
import shop from 'Assets/js/modules/shop';
import cocktailWidget from 'Assets/js/modules/cocktail-widget';
import vapLoadingFix from 'Assets/js/modules/vapLoadingFix';
import 'NodeModules/web-animations-js/web-animations.min';
require('Assets/js/modules/polyfills');
document.addEventListener('DOMContentLoaded', () => {
    copyToClipboard();
    checkDevice();
    const loadEShop = document.querySelector('html').dataset.eshop;
    siteModal();
    errorPage();
    const ageGateShowing = setInterval(function () {
        if (!document.body.classList.contains('avp-showing')) {
            clearInterval(ageGateShowing);
            setTimeout(welcomeMat, 3000);
        }
    }, 1000);
    cocktailWidget();
    vapLoadingFix();
    smoothscroll.polyfill();
    globalThis.requestAnimationFrame(() => {
        const header = document.querySelector('.site-header');
        siteHeader({ block: header });
    });
    lazyloader.options.filePath = 'js/blocks/';
    lazyloader.options.rootMargin = '100% 0px 300px 0px';
    lazyloader.options.assetArray = assets.dynamicAssets;
    lazyloader.options.debugLogMessages = false;
    lazyloader.lazyloaderInit();
    ttfb();
    if (loadEShop === 'true') {
        shop.checkCart(); // eager-loading Shopify?
    }
});
