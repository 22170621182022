import slidingForm from 'Assets/js/modules/sliding-form';

export default function modalJs() {
	try {
		// Close modal when a close button in the modal has been pressed
		const modals = document.querySelectorAll('.modal-tgl');
		const modalTriggers = document.querySelectorAll('.modal__trigger');

		const modalCloseButtons = document.querySelectorAll('.modal__close');
		const sortedModals = Array.from(modals).sort(
			(a, b) => a.dataset.order - b.dataset.order
		);

		// Move all modals to bottom of body so that position
		// of parent doesn't become an issue
		sortedModals.forEach((modal) => {
			document.body.appendChild(modal);
			slidingForm(modal);
		});

		modalTriggers.forEach((trigger) => {
			trigger.addEventListener('click', (event) => {
				event.preventDefault();
				const {modalName} = trigger.dataset;
				const thisModal = document.querySelector(
					`.modal-tgl[data-modal-name="${modalName}"]`
				);

				if (thisModal) {
					const container =
						thisModal.querySelector('.modal__container');
					const {direction} = thisModal.dataset;
					container.classList.remove('fadeInLeft');
					container.classList.remove('fadeInRight');
					container.classList.remove('fadeOutLeft');
					container.classList.remove('fadeOutRight');
					thisModal.classList.add('modal--visible');

					if (direction === 'left') {
						container.classList.add('fadeInLeft');
					} else if (direction === 'right') {
						container.classList.add('fadeInRight');
					}

					thisModal.parentElement.classList.add('modal-active');
					document.body.classList.add('modal-active');
				}
			});
		});

		modalCloseButtons.forEach((closeButton) => {
			closeButton.addEventListener('click', (event) => {
				event.preventDefault();
				// remove instead of toggle because it might activate inactive modals on the page
				const activeModal = document.querySelector('.modal--visible');
				const container =
					activeModal.querySelector('.modal__container');
				const {direction} = activeModal.dataset;
				if (direction === 'left') {
					container.classList.add('fadeOutLeft');
				} else if (direction === 'right') {
					container.classList.add('fadeOutRight');
				}
				document.body.classList.remove('modal-active');
				setTimeout(() => {
					activeModal.classList.remove('modal--visible');
					container.classList.remove('fadeInLeft');
					container.classList.remove('fadeInRight');
					container.classList.remove('fadeOutLeft');
					container.classList.remove('fadeOutRight');
				}, 800);
			});
		});
	} catch (error) {
		console.error(error);
	}
}
